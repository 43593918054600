<template>
  <div class="myteam">
    <div class="myteam-header-box">
      <div class="myteam-header">
        <div class="myteam-header-left">
          <div class="myteamheader">
            <img
              v-if="text.companyHeadPortrait"
              :src="text.companyHeadPortrait"
            >
            <img v-else src="../../../assets/images/defaulthead.png">
          </div>
          <div>{{ text.companyName }}</div>
        </div>
        <div class="myteam-header-right">
          <img src="../../../assets/images/titlebg.png">
        </div>
      </div>
      <div class="myteam-concent">
        <div>
          <span
            class="textleft"
          >参与人数：{{ text.participationNumber }}人</span>
          <span>排名：{{ text.participationRanking }}</span>
          <span v-if="show" class="btnspan" @click="configShare">去邀请</span>
        </div>
        <div>
          <span class="textleft">GMV：{{ text.salesVolume }}</span>
          <span>排名：{{ text.salesVolumeRanking }}</span>
          <span v-if="show" class="btnspan" @click="configShare">去分享</span>
        </div>
        <div>
          <span class="textleft">有效拉新：{{ text.invitationNumber }}</span>
          <span>排名：{{ text.invitationRanking }}</span>
          <span v-if="show" class="btnspan" @click="configShare">去邀请</span>
        </div>
      </div>
    </div>
    <div class="kingbox">
      <div class="kingbox-header">
        <!-- <img src="../../../assets/images/kingright.png" /> -->
        <span>团队之星</span>
        <!-- <img src="../../../assets/images/kingleft.png" /> -->
      </div>
      <div class="kingbox-content">
        <div class="king">
          <div v-if="num1 && num1.length > 1" class="king1">
            <div class="king1-header">
              <img src="../../../assets/images/No.2.png" alt="" class="no1">
              <img
                v-if="num1[1].litpic.length == 0"
                src="../../../assets/images/defaulthead.png"
                alt=""
                class="no1-h"
              >
              <img v-else class="imggg" :src="num1[1].litpic" alt="">
            </div>
            <div class="kingtext">
              <div class="kingtitle">
                <span>{{ num1[1].nickname }}</span>
              </div>
              <div class="kingdd">
                订单: <span class="spantext">{{ num1[1].num }}</span>单
              </div>
              <div class="kingdd">
                金额: <span class="spantext">{{ num1[1].sum_price }}</span>元
              </div>
            </div>
          </div>
          <div v-if="num1 && num1.length > 0" class="king1 king2">
            <div class="king1-header king1-header2">
              <img src="../../../assets/images/No.1.png" alt="" class="no1">
              <img
                v-if="num1[0].litpic.length == 0"
                src="../../../assets/images/defaulthead.png"
                alt=""
                class="no1-h"
              >
              <img v-else class="imggg" :src="num1[0].litpic" alt="">
            </div>
            <div class="kingtext">
              <div class="kingtitle">
                <span>{{ num1[0].nickname }}</span>
              </div>
              <div class="kingdd">
                订单: <span class="spantext">{{ num1[0].num }}</span>单
              </div>
              <div class="kingdd">
                金额:<span class="spantext">{{ num1[0].sum_price }}</span>元
              </div>
            </div>
          </div>
          <div v-if="num1 && num1.length > 2" class="king1 king3">
            <div class="king1-header king1-header3">
              <img src="../../../assets/images/No.3.png" alt="" class="no1">
              <img
                v-if="num1[2].litpic.length == 0"
                src="../../../assets/images/defaulthead.png"
                alt=""
                class="no1-h"
              >
              <img v-else class="imggg" :src="num1[2].litpic" alt="">
            </div>
            <div class="kingtext">
              <div class="kingtitle">
                <span>{{ num1[2].nickname }}</span>
              </div>
              <div class="kingdd">
                订单:<span class="spantext">{{ num1[2].num }}</span>单
              </div>
              <div class="kingdd">
                金额:<span class="spantext">{{ num1[2].sum_price }}</span>元
              </div>
            </div>
          </div>
        </div>
        <div v-if="num2 && num2.length !== 0" class="kingtou">
          <span>排名</span>
          <span>名称</span>
          <span>订单数量</span>
          <span>订单金额</span>
        </div>

        <div v-if="num2 && num2.length !== 0" class="kingtouitem">
          <div v-for="(item, index) in num2" :key="index" class="itembox">
            <span>{{ index + 4 }}</span>
            <span>{{ item.nickname }}</span>
            <span>{{ item.num }}</span>
            <span>{{ item.sum_price }}</span>
          </div>
        </div>

        <div v-else class="kingtouitem">暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import { MyTeam } from '@/services/activity.js'
import { getClientInfo } from '@/utils/public.js'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  // import引入的组件需要注入到对象中才能使用
  data() {
    // 这里存放数据
    return {
      // companyHeadPortrait:'',
      // companyName:'',
      // invitationNumber:0,
      // invitationRanking:0,
      // participationNumber:0,
      // participationRanking:0,
      // salesVolume:0,
      // salesVolumeRanking:0,
      text: {},
      num1: [],
      num2: [],
      show: false,
      topBannerData: null, // banner 数据
      mainList: [],
      env_rn: '', // 访问终端
      proParams: {
        epId: '',
        page: 1,
        pageSize: 10,
        loading: false,
        finished: false,
        error: false
      },
      shareData: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  beforeCreate() {
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
    })
    this.getMyTeam()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.RNshareDataChange = this.RNshareDataChange
    if (getClientInfo()) {
      this.show = true
    }
    // this.configShare();
  },
  destroyed() {
    // this.configShare();
  },
  // 方法集合
  methods: {
    // RN端发送分享信息
    RNshareDataChange() {
      const _url =
        window.location.href + '?codeNumber=' + this.shareData.codeNumber
      const _Router = {
        eventName: 'share',
        url: _url,
        image: this.shareData.image,
        title: this.shareData.title,
        des: this.shareData.desc
      }
      this.$store.dispatch('RNrouter', _Router)
    },

    async getMyTeam() {
      const uid = {
        mid: window.localStorage.getItem('uid') || ''
      }
      if (getClientInfo()) {
        uid.mid = this.$route.query.mid
      } else {
        uid.mid = localStorage.getItem('uid')
      }
      const res = await MyTeam(uid)
      if (Number(res.code) === 400) {
        this.$store.dispatch('loginIntercept', this.$route.query)

        // this.$router.push({
        //   path: "/login",
        // });
      }

      this.text = res.data.company
      // 取前三
      this.shareData = res.data.shareData
      res.data.userList.forEach((ele, idx) => {
        if (idx < 3) {
          this.num1.push(ele)
        } else {
          this.num2.push(ele)
        }
      })
    },
    configShare() {
      if (getClientInfo()) {
        this.RNshareDataChange()
      } else {
        // const url =
        //   window.location.href + '?codeNumber=' + this.shareData.codeNumber
        const shareInfo = {
          title: '双十二会场', // 分享标题
          desc: '双十二', // 分享描述
          link: decodeURIComponent(window.location.href), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: '' // 分享图标
        }
        wxConfigInit(shareInfo)
        // this.$store.dispatch('wxConfigInit', shareInfo)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.myteam {
  width: 100%;
  // height: 100vh;
  background: url("../../../assets/images/bg.png");
  background-size: 100% 100%;

  .myteam-header-box {
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .myteam-header {
      width: 90%;
      height: 20%;
      background: url("../../../assets/images/headerbg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;

      .myteam-header-left {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: center;
        color: #fe5d79;

        .myteamheader {
          width: 45px;
          height: 45px;
          margin: 0px 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
      }

      .myteam-header-right {
        width: 30%;
        height: 30%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .myteam-concent {
      width: 96%;
      height: 60%;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC Medium, PingFang SC Medium-Medium;
      font-weight: bold;
      color: #ff8379;

      div {
        width: 96%;
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        span {
          width: 33%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .btnspan {
          width: 30%;
          height: 80%;
          background: linear-gradient(-5deg, #ff8379 0%, #ffb0a9 100%);
          font-size: 14px;
          font-weight: bold;
          color: #ffffff;
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .textleft {
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }

  .kingbox {
    width: 96%;
    // height: 60vh;
    background: url("../../../assets/images/kingbg.png");
    background-size: 100% 100%;
    margin-left: 2%;
    padding: 10px 0 30px 0;
    // padding-top: 5%;
    .kingbox-header {
      padding: 10px;
      width: 100%;
      // height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 10%;
        height: 80%;
      }

      span {
        width: 25%;
        text-align: center;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
      }
    }

    .kingbox-content {

      width: 90%;
      height: 85%;
      background: url("../../../assets/images/footerbg.png");
      background-size: 100% 100%;
      margin-left: 5%;
      padding-top: 20px;

      .king {
        width: 100%;
        height: 50%;
        display: flex;

        .king1 {
          width: 30%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 25px;

          > .king1-header {
            width: 70px;
            height: 70px;
            background: url("../../../assets/images/num2.png");
            background-size: 100% 100%;
            position: relative;
            margin-right: 10px;

            .imggg {
              position: absolute;
              width: 52px;
              height: 55px;
              bottom: 1.5px;
              right: 2px;
              border-radius: 100%;
            }

            .no1 {
              width: 100%;
              height: 35%;
              position: absolute;
              bottom: 0;
              left: 6px;
              z-index: 999;
            }

            .no1-h {
              width: 54px;
              height: 54px;
              position: absolute;
              bottom: 2px;
              right: 2px;
              z-index: 99;
            }
          }

          > .king1-header2 {
            width: 76px;
            height: 76px;
            background: url("../../../assets/images/num1.png");
            background-size: 100% 100%;
            position: relative;

            .imggg {
              position: absolute;
              width: 59px;
              height: 59px;
              border-radius: 100%;
              bottom: 2.5px;
              right: 1px;
            }

            .no1-h {
              width: 60px;
              height: 62px;
              position: absolute;
              bottom: 2px;
              right: 2px;
              z-index: 99;
            }
          }

          > .king1-header3 {
            // width: 68px;
            // height: 68px;
            background: url("../../../assets/images/num3.png");
            background-size: 100% 100%;
            margin-left: 15px;
          }

          .kingtext {
            width: 100%;

            .kingtitle {
              font-size: 14px;
              font-weight: 700;
              overflow: hidden;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            .spantext {
              color: red;
              font-size: 14px;
            }

            .kingdd {
              font-size: 10px;
              font-weight: 600;
            }

            div {
              margin-top: 3px;
            }
          }
        }

        .king2 {
          width: 40%;
          margin-top: -5px;
        }

        .king3 {

          .king1-header3 {
            position: relative;

            .imggg {
              position: absolute;
              width: 54px;
              height: 53px;
              border-radius: 100%;
              bottom: 1px;
              left: 3px;
            }

            .no1-h {
              width: 56px;
              height: 53px;
              position: absolute;
              bottom: 2px;
              right: 9px;
              z-index: 99;
            }

            .no1 {
              width: 100%;
              height: 35%;
              position: absolute;
              bottom: 0;
              left: -3px;
              z-index: 999;
            }
          }
        }
      }

      .kingtou {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        // overflow: hidden;
        padding: 20px 0;

        span {
          width: 25%;
        }
      }

      .kingtouitem {
        width: 100%;
        height: 20%;
        min-height: 200px;
        overflow: hidden;
        overflow-y: scroll;

        .itembox {
          width: 100%;
          padding: 10px 0;
          // height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            width: 25%;
            // padding: 20px 0;
            // height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
